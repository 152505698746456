import {Component} from 'react'
import { connect } from 'react-redux'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import Image from 'react-bootstrap/Image'
import Article from './article'
import Modal from 'react-bootstrap/Modal'


import {format_price, make_price} from './util'
import { api_add_credits, api_get_credits} from './api'

import {CLEAR_PAYMENT, STORE_PAYMENT} from './actions'
import { use_payconiq }  from './config'


class Credits extends Component {
	
	static credits = [
		{id:1, name:"Bedrag van", price:0.1},
		{id:2, name:"Bedrag van", price:1},
		{id:3, name:"Bedrag van", price:5},
		{id:4, name:"Bedrag van", price:10},
		{id:5, name:"Bedrag van", price:20},
		{id:6, name:"Bedrag van", price:50}
		]
		
	constructor(props){
		super(props)
		this.state = {counts:{"1":0,"2":0,"3":0,"4":0,"5":0,"6":0}, usercredits:0, msg:{}, showMsg:false}
		this.countChanged = this.countChanged.bind(this)
		this.addCredits = this.addCredits.bind(this)
		this.mounted = false
	}
	
	componentWillUnmount(){
		this.mounted = false
	}
	
	componentDidMount(){
		this.mounted = true
		api_get_credits(this.props.user_id)
		.then(result => {
			if (this.mounted) {
				if (result.result){
					this.setState({usercredits:parseFloat(result.data.credits)})
				} else {
					console.log(result.error.msg)
					this.setState({msg:{title:"Transactie GEFAALD", message:result.error.msg}, showMsg:true})
				}
			}
		})
		.catch( err => {
			if (this.mounted) {
				console.log(err)
				this.setState({msg:{title:"Transactie GEFAALD", message:err}, showMsg:true})
			}
		})
	}
	
	render(){
		let tot_price = 0
		for ( let article_id in this.state.counts)
		{
		
			let count = this.state.counts[article_id]
			let id = parseFloat(article_id)
			let credit = Credits.credits.find(credit => credit.id === id)
			if (credit !== undefined)
			{
				let credit_price = parseFloat(credit.price)
				tot_price = tot_price + count*credit_price
			}
		}
		
		tot_price = make_price(tot_price)
		
		
		return 	<>
					<Stack direction="vertical" gap={1}>
						<div key={"saldo"} style={{width:'100%', textAlign:'center', fontSize:'20px'}}><strong>Saldo: {format_price(this.state.usercredits)}€</strong></div>
						{
							Credits.credits.map( credit =>	{
								return (<div style={{width:'100%'}} key={credit.id}>
									<Article key={credit.id} name={credit.name} price={credit.price} minimum={-parseInt((this.state.usercredits+tot_price+(-this.state.counts[credit.id]*credit.price))/credit.price)} count={this.state.counts[credit.id]} onChange={(cnt)=>this.countChanged(credit.id,cnt)}/>
							</div>)})
						}
						<div key={"cash"} style={{width:'100%'}}>
							<Button disabled={tot_price===0} variant={tot_price>0?"success":(tot_price<0?"danger":"info")} style={{width:'100%'}} onClick={()=>this.addCredits(this.props.user_id, tot_price)}>
								{tot_price<0 && <>{format_price(-tot_price)} € terugbetalen</>}
								{tot_price>0 && <>{format_price(tot_price)} € aanrekenen</>}
								{tot_price===0 && <>Kies een bedrag en reken af</>}
							</Button>
						</div>
						{
							tot_price>0 && use_payconiq &&
						<div key={"payconiq"} style={{width:'100%'}}>
							<Button disabled={tot_price===0} variant={tot_price>0?"success":(tot_price<0?"danger":"info")} style={{width:'100%'}} onClick={()=>this.addCredits(this.props.user_id, tot_price,"payconiq")}>
								{tot_price<0 && false && <>{format_price(-tot_price)} € terugbetalen via <Image src="payconiq256.png"  style={{height:40, color:"red"}} /></>}
								{tot_price>0 && <>{format_price(tot_price)} € aanrekenen via <Image src="payconiq256.png"  style={{height:40, color:"red"}} /></>}
								
							</Button>
						</div>
						}
						{ this.state.showMsg && <div>
							<Toast show={this.state.showMsg} style={{width:'100%'}}
								onClose={() => { this.setState({showMsg:false})}}	>
								<Toast.Header><strong className="me-auto">{this.state.msg.title}</strong></Toast.Header>
								<Toast.Body>{this.state.msg.message}</Toast.Body>
								</Toast>
						</div>}
					</Stack>
					{
						this.props.payment!==undefined && this.props.payment.links!==undefined && this.props.payment_status!==undefined && 
							<Modal show={this.props.payment_status.status!==undefined}  size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
								<Modal.Header>
									{ this.props.payment_status.status==="PENDING" && <Modal.Title>Wachten op betaling...</Modal.Title>}
									{ this.props.payment_status.status==="CANCELLED" && <Modal.Title>Betaling afgebroken</Modal.Title>}
									{ this.props.payment_status.status==="SUCCEEDED" && <Modal.Title>Betaling Gelukt!</Modal.Title>}
									{ this.props.payment_status.status==="EXPIRED" && <Modal.Title>Betaling vervallen</Modal.Title>}
								</Modal.Header>
								<Modal.Body>
									<div style={{backgroundColor:'#ff4785', borderRadius:'20px', padding:'10px'}}>
									<div className="text-center" style={{fontWeight:'bold', fontSize:'1.5em', color:'white'}}>Scan and pay with</div>
									<div className="text-center" style={{fontWeight:'bold', fontSize:'1.5em', color:'white'}}>Payconiq by Bancontact</div>
									<div className="text-center"><Image className="mx-auto" src={'payconiq_bc.png'} style={{height:100}}/></div>
									<div className="text-center"><Image className="mx-auto" src={this.props.payment.links.qrcode.href}/></div>
									<div className="text-center" style={{fontWeight:'bold', fontSize:'1.5em', color:'white'}}>{format_price(this.props.payment_status.amount)} €</div>
									</div>
								</Modal.Body>
								<Modal.Footer>
								{/*<Button variant="success" onClick={()=>this.setState({showQrCode:true})}>QR-code</Button>*/}
									<Button variant="success" onClick={()=>this.props.clearPayment()}>Sluiten</Button>
								</Modal.Footer>
							</Modal>
					}
				</>
	}
	
	countChanged(id, cnt){
		let counts = {...this.state.counts}
		counts[id] = cnt
		this.setState({counts})
	}
	
	addCredits(user_id, amount, paymentMethod){
		api_add_credits(this.props.identifier, user_id, amount, paymentMethod)
		.then(result => {
			if (result.result){
				if (paymentMethod==="payconiq")
				{
					this.props.storePayment(result["data"])
					//window.open(result["data"]["links"]["deeplink"]["href"], '_blank');
				}
				else
				{
					if (this.mounted)
						this.setState({msg:{title:"Transactie OK", message:(amount>0?("Vraag "+format_price(amount)+" € aan de klant"):("Geef "+format_price(-amount)+" € aan de klant"))}, usercredits:parseFloat(result.data.credits), counts:{}, showMsg:true})
				}
			} else {
				console.log(result.error.msg)
				this.setState({msg:{title:"Transactie GEFAALD", message:result.error.msg}, showMsg:true})
			}
		})
		.catch(err => {
			console.log(err)
			this.setState({msg:{title:"Transactie GEFAALD", message:err}, showMsg:true})
		})
	}
}

function mapDispatchToProps(dispatch) {
	return {
		storePayment: (payment) => {dispatch({ type: STORE_PAYMENT, payment  })},
		clearPayment: () => {dispatch({ type: CLEAR_PAYMENT  })}
		}
}

function mapStateToProps(state) {
	return {identifier:state.app.identifier, 
	payment_status:state.order.payment_status,
	payment:state.order.payment}
	
}
   
export default connect(mapStateToProps, mapDispatchToProps)(Credits);  
